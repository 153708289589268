import React from "react";
import "../css/EurShop.css";
import data from "../assets/data/ShopUs.json";

const EurShop = () => {
  return (
    <div className="Title-Display">
      <h1 className="Title3">SHOP UNITED STATES</h1>
      <div className="Shop-Container">
        <div className="Title-Shop">
          <h1 className="Title1">SHOP </h1>
          <h1 className="Title2">UNITED</h1>
          <h1 className="Title2">STATES</h1>
        </div>
        <div className="Card-Item6">
          {data.ShopUs.map((elem, index) => {
            return (
              <div className="Item6">
                <img className="Product-Img6" src={elem.image1} />
                <h4 className="h2">{elem.name}</h4>
                <h className="h">{elem.price}</h>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default EurShop;
