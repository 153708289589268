import React from "react";
import "../css/AusShop.css";
import data from "../assets/data/ShopEur.json";

const AusShop = () => {
  return (
    <div className="Title-Display">
      <h1 className="Title3">SHOP EUROPE</h1>
      <div className="Shop-Container">
        <div className="Title-Shop">
          <h1 className="Title1">SHOP</h1>
          <h1 className="Title2">EUROPE</h1>
        </div>
        <div className="Card-Item1">
          {data.ShopEur.map((elem, index) => {
            return (
              <div className="Item">
                <img className="Product-Img1" src={elem.image1} />
                <h4 className="h2">{elem.name}</h4>
                <h className="h">{elem.price}</h>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default AusShop;
