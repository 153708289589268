import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../css/Home.css";
import NavBar from "../containers/NavBar";
import News from "../containers/News";
import UsShop from "../containers/UsShop";
import AusShop from "../containers/AusShop";
import EurShop from "../containers/EurShop";
import WwShop from "../containers/WwShop";




function Home() {
  return (
    <div>

      <NavBar />
      <News />
      <UsShop />
      <AusShop />
      <EurShop />
      <WwShop />
    </div>
  );
}
export default Home;
