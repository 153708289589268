import React from "react";
import "../css/News.css";
import image from "../assets/img/back.jpg";

function News() {
  return (
    <div className="Center-Container">
      <div className="Container-News">
        <img className="back" src={image} />
      </div>
    </div>
  );
}

export default News;
