import React from "react";
import "../css/NavBar.css";
import logo from "../assets/img/OLA.png";

function NavBar() {
  return (
    <div className="Container-NavBar">
      <nav className="Menu-NavBar">
        <div className="Logo-OLA">
          <img className="OLA" src={logo} alt="" />
        </div>
        <ul className="Nav-Links">
          <li>
            <a href="Shop-Link">HOME</a>
          </li>
          <li>
            <a href="https://www.coldcutsmerch.com/collections/one-love-apparel ">
              SHOP US
            </a>
          </li>
          <li>
            <a href="https://merchfan.co/collections/oneloveapparel">
              SHOP AUS
            </a>
          </li>
          <li>
            <a href="https://www.impericon.com/de/one-love-apparel.html">
              SHOP EUR
            </a>
          </li>
          <li>
            <a href="https://oneloveordie.com/">SHOP WORLDWIDE</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default NavBar;
